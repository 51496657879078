
angular
	.module('servicepro.centereditor')
	.controller('CenterEditorController', [
		'$log',
		'$scope',
		'$rootScope',
		'$filter',
		'$resource',
		'$timeout',
		'gettextCatalog',
		'apiURLs',
		'$http',
		'$compile',

		function CenterEditorControllerFactory($log, $scope, $rootScope, $filter, $resource, $timeout, gettextCatalog, apiURLs, $http, $compile) {
            $log.debug('CenterEditorControllerFactory OK');
            //$scope.context = centerEditorContext;  // Return $resource to fetch centers, or null if unavailable
			// (depends on current client)
			$scope.state = {
				loading: false,
				equipments: [],
			};

			$scope.get_client_resource = function() {
				return $resource(apiURLs.clients, {});
			};
			$scope.get_equipment_resource = function() {
				return $resource(apiURLs.equipmentmodels, {});
			};
			$scope.add_equipment = function () {
				$log.debug("añadir equipo");
				var form_idx = $('#id_equipment-TOTAL_FORMS').val(),
					html_text = $('#empty_form').html().replace(/__prefix__/g, form_idx);
				var html_compiled = $compile(html_text)($scope);
				angular.element($('#teamsList').append(html_compiled));
				dinamicDatePickerEquiment(form_idx);
				$('#id_equipment-TOTAL_FORMS').val(parseInt(form_idx) + 1);
            };
        }
    ]);