
// Catalog of messages used in templates
// This is needed for makemessages to detect gettext messages (djangojs domain)
// without implementing an actual preprocessor in python (TO-DO?).
// Translated messages actually come from Django's gettext() tool
// UPDATE: angular-gettext is almost perfect, but we would need to update
// .po files on changes to the generated .pot. Also missing sane attribute translation.
angular
	.module('servicepro.gettext')
	.factory('CATALOG', [
		'$log',
		
		function gettextServiceFactory($log) {
			// $log.debug('gettextServiceFactory OK');
			
			var catalog = {
				"": gettext_noop("")
			};
			
			return catalog;
		}
	]);


