angular
	.module('servicepro.analysis', [
		'ngResource',
        'ui.bootstrap',
        'servicepro.analysis.summary',
        'servicepro.analysis.incidences',
        'servicepro.analysis.hours',
        'servicepro.analysis.amounts',
	])

    .value('lineChartOptions', function(labelString, ticksYSuffix) {
        return {
            responsive: true,
            maintainAspectRatio: false,
            legend: {
                display: false
            },
            tooltips: {
                displayColors: false,
                backgroundColor: '#7c92a0',
                bodyFontSize: 11,
                bodyFontStyle: 'normal',
                bodyFontColor: "#efefef",
                callbacks: {
                    title: function(tooltipItem, data) {
                        return '';
                    },
                    label: function(tooltipItem, data) {
                        return ' '+tooltipItem.yLabel;
                    }
                }
            },
            scales: {
                yAxes: [{
                    ticks: {
                        callback: function(value, index, values) {
                            if(ticksYSuffix) {
                                return value + ' ' + ticksYSuffix;
                            } else {
                                return value;
                            }
                        },
                        min: 0,
                        suggestedMax: 10,
                        beginAtZero: true,
                        stepSize:5,
                        fontColor: '#68707d',
                        fontSize: 12,
                        fontFamily: '"Open Sans", Helvetica, Arial, sans-serif'
                    }
                }],
                xAxes: [{
                    ticks: {
                        autoSkip: true,
                        maxTicksLimit: 10,
                        fontColor: '#68707d',
                        fontSize: 12,
                        fontFamily: '"Open Sans", Helvetica, Arial, sans-serif'
                    },
                    scaleLabel: {
                        display: true,
                        labelString: labelString,
                        fontColor: '#68707d',
                        fontSize: 18,
                        fontFamily: '"Open Sans", Helvetica, Arial, sans-serif',
                        padding: 14
                    }
                }]
            }
        };
    })

    .value('verticalBarChartOptions', function(ticksSuffix) {
        return {
            responsive: true,
            maintainAspectRatio: false,
            legend: {
                display: false
            },
            tooltips:{
              displayColors: false,
              backgroundColor: '#7c92a0',
              bodyFontSize: 11,
              bodyFontStyle: 'normal',
              bodyFontColor: "#efefef",
              callbacks: {
                  title: function(tooltipItem, data) {
                    return '';
                  },
                  label: function(tooltipItem, data) {
                    //console.log(tooltipItem,'//', data);
                    // console.log(tooltipItem.xLabel);
                    return ' '+tooltipItem.xLabel;
                  }
              }
            },
            scales: {
                yAxes: [{
                    display: true,
                    barThickness : 30,
                    ticks: {
                        callback: function(value, index, values) {
                            if(ticksSuffix) {
                                return value + ' ' + ticksSuffix;
                            } else {
                                return value;
                            }
                        },
                        stepSize: 100,
                        beginAtZero: true,
                        fontColor: '#68707d',
                        fontSize: 10,
                        fontFamily: '"Open Sans", Helvetica, Arial, sans-serif'
                    },
                    gridLines: {
                        //color: 'transparent'
                        display: false,
                        drawBorder: false, //hide the chart edge line
                        tickMarkLength: 15 // espacio entre ticks y líneas
                    }
                }],
                xAxes: [{
                    display: true,
                    position: 'bottom',
                    ticks: {
                        fontSize: 12,
                        padding: 10,
                        //fontColor: 'transparent'
                    },
                    gridLines: {
                        drawBorder: false,
                        zeroLineWidth:4, // Grosor línea inicial
                        zeroLineColor:'#f4f4f6', // Color línea inical
                        lineWidth: 2, // Grosor líneas
                        color: '#f4f4f6' // Color Líneas
                    }
                }]
            }
        };
    });