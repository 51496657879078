angular
    .module('servicepro', [
        'ngResource',
        'gettext',

        'servicepro.common',
		'servicepro.modalselector',
		'servicepro.modelwidget',
		'servicepro.daterangewidget',
		'servicepro.centereditor',
		'servicepro.clienteditor',
		'servicepro.workordereditor',
		'servicepro.selectworker',
		'servicepro.analysis',
		'servicepro.workorderresulteditor'

    ])

    .constant('DEBUG', DEBUG)
    .constant('APP_CONTEXT', APP_CONTEXT)

    .config([
        '$httpProvider',
        '$logProvider',
		'$resourceProvider',
		'$locationProvider',
		
        function($httpProvider, $logProvider, $resourceProvider, $locationProvider) {
			console.log('servicepro config() OK');
			
			// $httpProvider
			$httpProvider.defaults.xsrfCookieName = 'csrftoken';
			$httpProvider.defaults.xsrfHeaderName = 'X-CSRFToken';
			
			// $logProvider
			var debug = (typeof DEBUG !== 'undefined') ? DEBUG : false;
			$logProvider.debugEnabled(debug);
			
			// $resourceProvider
			// Set paginated response gobally for query()
			// with default args for first page and page size
			// TODO: pass page sizes from django with a common app context
			$resourceProvider.defaults.actions.query = {
				method: 'GET',
				params: {page: 1, page_size: 5 },
				isArray: false
			};
			// DRF Update (PUT)
			$resourceProvider.defaults.actions.update = {
				method: 'PUT'
			};
			
			// $locationProvider
			$locationProvider.html5Mode({
				enabled: true,
				requireBase: false,
				rewriteLinks: false
			});
		}
    ])

    .config([
	    'APP_CONTEXT',
		function(APP_CONTEXT) {
		    var lang = 'es';
			if (APP_CONTEXT.lang) {
				lang = APP_CONTEXT.lang;
			}

			Date.prototype.getMonthName = function() {
                return Date.locale[lang].month_names[this.getMonth()];
            };

            Date.prototype.getMonthNameShort = function() {
                return Date.locale[lang].month_names_short[this.getMonth()];
            };

            Date.locale = {
                en: {
                   month_names: ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'],
                   month_names_short: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec']
                },
                es: {
                   month_names: ['Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio', 'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre'],
                   month_names_short: ['Ene', 'Feb', 'Mar', 'Abr', 'May', 'Jun', 'Jul', 'Ago', 'Sep', 'Oct', 'Nov', 'Dic']
                },
                pt: {
                   month_names: ['Janeiro', 'Fevereiro', 'Março', 'Abril', 'Maio', 'Junho', 'Julho', 'Agosto', 'Setembro', 'Outubro', 'Novembro', 'Dezembro'],
                   month_names_short: ['Jan', 'Fev', 'Mar', 'Abr', 'Mai', 'Jun', 'Jul', 'Ago', 'Set', 'Out', 'Nov', 'Dez']
                }
            };
		}
	])

	// Check APP_CONTEXT
	.run([
		'$log',
		'APP_CONTEXT',
		function($log, APP_CONTEXT) {
			if (!APP_CONTEXT) {
				$log.error("Missing APP_CONTEXT");
			}
		}
	])

	// Setup language
	.run([
		'$log',
		'gettextCatalog',
		'APP_CONTEXT',
		function($log, gettextCatalog, APP_CONTEXT) {

			var locale_default = 'es';
			if (APP_CONTEXT.lang) {
				$log.debug("Setting locale from context: " + APP_CONTEXT.lang);
				gettextCatalog.setCurrentLanguage(APP_CONTEXT.lang);

			} else {
				$log.debug("Language code was not found in context.");
				$log.debug("Setting default locale: " + locale_default);
				gettextCatalog.setCurrentLanguage(locale_default);
			}

			// Debug
			gettextCatalog.debug = DEBUG;
		}
	]);