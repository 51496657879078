angular
	.module('servicepro.analysis.incidences')
	.controller('IncidencesController', [
		'$log',
		'$scope',
		'$rootScope',
		'$filter',
		'$resource',
		'$timeout',
		'gettextCatalog',
		'apiURLs',
		'$http',
		'$compile',
		'lineChartOptions',

		function IncidencesControllerFactory($log, $scope, $rootScope, $filter, $resource, $timeout, gettextCatalog, apiURLs, $http, $compile, lineChartOptions) {
            $log.debug('IncidencesControllerFactory OK');

			var dateEndDt = moment(),
				dateStartDt = moment(dateEndDt).subtract(1, 'weeks'),
				linesChart;

			$scope.state = {
				init: true,
				loading: true,
				filters: { // API filters
					daterange: {
						start: dateStartDt.format('YYYY-MM-DD'), // 'YYYY-MM-DD'
						end: dateEndDt.format('YYYY-MM-DD') // 'YYYY-MM-DD'
					}
				}
			};

			$scope.drawLinesChart = function(requestsData) {
                $("#incidences-total-results").html(requestsData.totals.results);
                $("#incidences-total-issues").html(requestsData.totals.issues);
                $("#incidences-total-average").html(requestsData.totals.average + " %");

                var colorLines = {
                    results: '#1ba4d3',
                    issues: '#f17e5f',
                };

                var labelsDates = requestsData.per_dates.labels;
                var labels = [];
                var months = [];
                labelsDates.forEach(function(part, index) {
                    labels[index] = new Date(part).getDate();
                    months[index] = new Date(part).getMonthName();
                });
                months = Array.from(new Set(months)).join(' / ');

                var dataSet = [];
                for(var item in requestsData.per_dates.counts) {
                    var data = {
                        borderColor: colorLines[item],
                        pointBorderColor: colorLines[item],
                        data: requestsData.per_dates.counts[item]
                    };
                    dataSet.push(data);
                }

                if (linesChart instanceof Chart) {
                    $("#incidences-line-chart").empty();
                    linesChart.destroy();
                }
                linesChart = new Chart($("#incidences-line-chart"), {
                    type: 'line',
                    data: {
                        labels: labels,
                        datasets: dataSet,
                    },
                    options: lineChartOptions(months)
                });
			};

			// API opts based on state
			$scope.getApiOptions = function(overrides) {
				var options = {};
				// API filters
				if ($scope.state.filters.daterange) {
					options.date_from = $scope.state.filters.daterange.start;
					options.date_to = $scope.state.filters.daterange.end;
				}
				// Apply overrides
				angular.extend(options, overrides);
				return options;
			};

			// Draw charts
			$scope.draw = function() {
                $resource(apiURLs.analysis.incidences).query(
			        $scope.getApiOptions(),
					function(requestsData) {
						$scope.drawLinesChart(requestsData);
					}, function(requestsData) {
						$log.error("Incidences Data. FAILED");
					}
				);
			};

			// Watch for filters
			// Daterange: fetch from API
			$scope.$watchCollection('state.filters.daterange', function(nv, ov) {
				if (!$scope.state.init) {
					$scope.draw();
				}
			});

			// Init
			$scope.init = function() {
				$scope.state.init = false;
			};
			// Init
			$scope.init();
        }
    ]);