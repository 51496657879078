/**
 * Created by jcorrea on 24/07/17.
 */

angular
	.module('servicepro.workordereditor')
	.controller('WorkOrderEditorController', [
		'$log',
		'$scope',
		'$rootScope',
		'$filter',
		'$resource',
		'$timeout',
		'gettextCatalog',
		'apiURLs',
		'$http',
		'$compile',

		function WorkOrderEditorControllerFactory($log, $scope, $rootScope, $filter, $resource, $timeout, gettextCatalog, apiURLs, $http, $compile) {
            $log.debug('WorkOrderEditorControllerFactory OK');
            //$scope.context = centerEditorContext;  // Return $resource to fetch centers, or null if unavailable
			// (depends on current client)
			$scope.state = {
				loading: false,
				assistance_request: null,
				client: null,
				service_address: null,
				planning_technicians: [],
				equipments: [],
			};



			// Object to store the dynamics variables in Equipment formset.
			$scope.Equipments = {};

			$scope.get_assistance_request = function() {
			    return $resource(apiURLs.assistance_request, {});
            };

			$scope.get_new_assistance_request = function () {
				return $resource(apiURLs.assistance_request, {status: 'new'});
			};

			$scope.get_client = function () {
				if ($scope.state && $scope.state.service_address != null) {
					$log.debug("borrar service address");
					$scope.state.service_address = null;
				}
				return $resource(apiURLs.clients, {});
            };

			$scope.get_service_resource = function() {
				var opts = {};
				if ($scope.state.client != null) {
					opts = {client: $scope.state.client.id};
				}
				return $resource(apiURLs.service_address, opts);
			};

			$scope.get_service_resource_specific_id = function(id) {
				var opts = {};
				if (id != null) {
					opts = {client: id};
					return $resource(apiURLs.service_address, opts);
				}
				return false;
				
			};
			$scope.get_technician = function() {
				var items_selected = {};
				if (!(angular.isUndefined(this.$parent.$root.state))){
					if (this.$parent.$root.state.items_selected != null) {
						items_selected = {items_selected : {ids:this.$parent.$root.state.items_selected}};
					}
				}
				return $resource(apiURLs.technicians, items_selected);
			};

			// Equipments.
			$scope.get_equipment_resource = function() {
				return $resource(apiURLs.equipmentmodels, {});
			};
			$scope.add_equipment = function () {
				var form_idx = $('#id_workorderequipment_set-TOTAL_FORMS').val(),
					html_text = $('#empty_form').html().replace(/__prefix__/g, form_idx);
				var html_compiled = $compile(html_text)($scope);
				angular.element($('#teamsList').append(html_compiled));
				$('#id_workorderequipment_set-TOTAL_FORMS').val(parseInt(form_idx) + 1);
            };
        }
    ]);
