angular
	.module('servicepro.analysis.amounts')
	.controller('AmountsController', [
		'$log',
		'$scope',
		'$rootScope',
		'$filter',
		'$resource',
		'$timeout',
		'gettextCatalog',
		'apiURLs',
		'$http',
		'$compile',
		'verticalBarChartOptions',

		function AmountsControllerFactory($log, $scope, $rootScope, $filter, $resource, $timeout, gettextCatalog, apiURLs, $http, $compile, verticalBarChartOptions) {
            $log.debug('AmountsControllerFactory OK');

			var dateEndDt = moment(),
				dateStartDt = moment(dateEndDt).subtract(1, 'weeks'),
				barChart;

			$scope.state = {
				init: true,
				loading: true,
				filters: { // API filters
					daterange: {
						start: dateStartDt.format('YYYY-MM-DD'), // 'YYYY-MM-DD'
						end: dateEndDt.format('YYYY-MM-DD') // 'YYYY-MM-DD'
					}
				}
			};

			$scope.drawBarChart = function(requestsData) {
			    var labels = [];
			    for(var index in requestsData.labels) {
                    labels.push(gettextCatalog.getString(requestsData.labels[index]));
                }

                barChart = new Chart($("#amounts-bar-chart"), {
                    type: 'bar',
                    data: {
                        labels: labels,
                        datasets: [{
                            data: requestsData.counts,
                            backgroundColor: ['#1ba4d3', '#1ba4d3', '#1ba4d3', '#6ec563']
                        }]
                    },
                    options: verticalBarChartOptions("€")
                });
			};

			// API opts based on state
			$scope.getApiOptions = function(overrides) {
				var options = {};
				// API filters
				if ($scope.state.filters.daterange) {
					options.date_from = $scope.state.filters.daterange.start;
					options.date_to = $scope.state.filters.daterange.end;
				}
				// Apply overrides
				angular.extend(options, overrides);
				return options;
			};

			// Draw charts
			$scope.draw = function() {
                $resource(apiURLs.analysis.amounts).query(
			        $scope.getApiOptions(),
					function(requestsData) {
						$scope.drawBarChart(requestsData);
					}, function(requestsData) {
						$log.error("Amounts Data. FAILED");
					}
				);
			};

			// Watch for filters
			// Daterange: fetch from API
			$scope.$watchCollection('state.filters.daterange', function(nv, ov) {
				if (!$scope.state.init) {
					$scope.draw();
				}
			});

			// Init
			$scope.init = function() {
				$scope.state.init = false;
			};
			// Init
			$scope.init();
        }
    ]);