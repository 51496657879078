
angular
	.module('servicepro.modalselector', []);


// Modalselector resource templates
angular
	.module('servicepro.modalselector')
	.value('MODALSELECTOR_TEMPLATES', {
		generic: {
			header: null,
			item: '/static/servicepro/html/modalselector/modalselector-apiobject-choices-generic-item.html'
		},
		client: {
			header: null,
			item: '/static/servicepro/html/modalselector/modalselector-apiobject-choices-client-item.html'
		},
		center: {
			header: '/static/servicepro/html/modalselector/modalselector-apiobject-choices-center-header.html',
			item: '/static/servicepro/html/modalselector/modalselector-apiobject-choices-center-item.html'
		},
		equipment: {
			header: '/static/servicepro/html/modalselector/modalselector-apiobject-choices-equipment-header.html',
			item: '/static/servicepro/html/modalselector/modalselector-apiobject-choices-equipment-item.html'
		},
		technician: {
			header: null,
			item: '/static/servicepro/html/modalselector/modalselector-apiobject-choices-worker-item.html'
		},
		distributor: {
			header: '/static/servicepro/html/modalselector/modalselector-apiobject-choices-distributor-header.html',
			item: '/static/servicepro/html/modalselector/modalselector-apiobject-choices-distributor-item.html'
		},
		salesrep: {
			header: '/static/servicepro/html/modalselector/modalselector-apiobject-choices-salesrep-header.html',
			item: '/static/servicepro/html/modalselector/modalselector-apiobject-choices-salesrep-item.html'
		},
		servicerequest: {
			header: '/static/servicepro/html/modalselector/modalselector-apiobject-choices-servicerequest-header.html',
			item: '/static/servicepro/html/modalselector/modalselector-apiobject-choices-servicerequest-item.html'
		},
	});