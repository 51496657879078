
// django-gettext directive
// Replaces element's inner text with translation from catalog
// Uses automatic current language from Django's gettext()
angular
	.module('servicepro.gettext')
	.directive('djangoGettext', [
		'$log',
		'CATALOG',
		
		function djangoGettextFactory($log, CATALOG) {
			return {
				link: function(scope, element, attrs, controller) {
					// $log.debug("djangoGettextFactory.link() OK");
					var message = element.text();
					var translation = CATALOG[message];
					if (typeof translation !== 'undefined') {
						element.text(translation);
					}
				}
			};
		}
	]);


// django-gettext-attr directive
// Replaces an attribute's value with its translation
// the attribute is identified by the given attribute's name
angular
	.module('servicepro.gettext')
	.directive('djangoGettextAttr', [
		'$log',
		'CATALOG',
		
		function djangoGettextAttrFactory($log, CATALOG) {
			return {
				restrict: 'A',
				scope: {
					attribute_name: '@djangoGettextAttr'
				},
				link: function(scope, element, attrs, controller) {
					// $log.debug("djangoGettextAttrFactory.link() OK");
					// $log.debug(attrs);
					// $log.debug(scope.attribute_name);
					// $log.debug(element.attr(scope.attribute_name));
					
					// Get attribute by original attribute name
					// (attrs is always camel-cased)
					var message = element.attr(scope.attribute_name);
					if (!!message) {
						var translation = CATALOG[message];
						if (typeof translation !== 'undefined') {
							element.attr(scope.attribute_name, translation);
						}
					} else {
						$log.error("django-gettext-attr refers to attribute '" + scope.attribute_name + "' but it was not found.");
					}
				}
			};
		}
	]);


// django-gettext filter
angular
	.module('servicepro.gettext')
	.filter('djangogettext', [
		'$log',
		'CATALOG',
		
		function djangogettextFilterProvider($log, CATALOG) {
			return function(value) {
				var translation = CATALOG[value];
				if (typeof translation !== 'undefined') {
					return translation;
				} else {
					return value;
				}
			};
		}
	]);