
angular
	.module('servicepro.selectworker')
	.controller('SelectWorkerController', [
		'$log',
		'$scope',
		'$rootScope',
		'$filter',
		'$resource',
		'$timeout',
		'$http',
		'$location',
		'gettextCatalog',
		'apiURLs',

		function SelectWorkerControllerFactory($log, $scope, $rootScope, $filter, $resource, $timeout, $http, $location, gettextCatalog, apiURLs) {
			$log.debug('SelectWorkerControllerFactory OK');
			$scope.state = {
				loading: true,
				technician: null,
				frequency: false
			};

			// Init Search Fields from URL
			// this could be turned into a generic directive
			var args = $location.search();

            // Planning Frequency
            if (args.planning_frequency && /^true|false$/.test(args.planning_frequency)) {
			    $scope.state.frequency = JSON.parse(args.planning_frequency);
			}

            // Planning Technicians
			if (args.planning_technicians && /^\d+$/.test(args.planning_technicians)) {
				// $log.debug("Init technician from ID: " + args.technician);
				var technician_id = args.planning_technicians;
				
				// Fetch Technician model
				var options = {id: technician_id};
				$resource(apiURLs.technicians).get(options, function(data) {
					// $log.debug("Technician fetch OK.");
					// $log.debug(data);
					// Hackish workaround to a race condition between loading
					// state and watch activating with the intial load.
					$timeout(function() {
						$scope.state.technician = data;
						$timeout(function() {
							$scope.state.loading = false;
						});
					});
					
				}, function(data) {
					$log.debug("Technician fetch FAILED.");
					$log.debug(data);
					$scope.state.loading = false;

				});
			} else {
				// Nothing to load
				$scope.state.loading = false;
			}
			
			// Watch: Submit form when model changes
			// * Not loading (fetching initial model)
			// * Not identical
			// * At least one is truthy
			// * Use angular objectEquality (ignore resetting same object)
            $scope.$watch('state.technician', function(nv, ov) {
                // $log.debug("Technician changed!");
				$scope.submitSearchForm(nv, ov);
			}, true);

			$scope.$watch('state.frequency', function(nv, ov) {
				$scope.submitSearchForm(nv, ov);
			}, true);

			$scope.submitSearchForm = function (nv, ov) {
			    if (!$scope.state.loading && (nv !== ov) && (nv || ov)) {
					 // $log.debug(ov);
					 // $log.debug(nv);
					$timeout(function() {
						// $log.debug("Submit!");
						angular.element('#searchform').submit(); // Hack
					});

				}
			};
		}
	]);