
// API Object Modal Selector
// Generic modal directive to select one resource object from a Rest API
// suggested items, search, pagination

angular
	.module('servicepro.modalselector')
	.controller('apiObjectModalSelectorController', [
		'$log',
		'$scope',
		'$rootScope',
		'$element',
		'$attrs',
		'MODALSELECTOR_TEMPLATES',
		
		function apiObjectModalSelectorControllerFactory($log, $scope, $rootScope, $element, $attrs, MODALSELECTOR_TEMPLATES) {
			$log.debug('apiObjectModalSelectorControllerFactory OK');
			
			$scope.conf = {
				page_size: 5
			},
			$scope.state = {
				active: false,
				active_resource: null,
				modal: $($element).find('.modal'),
				modal_title: null,
				resource_kind: 'generic',
				resource_service: null,
				resource_service_suggested: null,
				resource_service_options: {}, // TODO: deprecate resource_service_options
				choices_header_template_url: MODALSELECTOR_TEMPLATES.generic.header,
				choices_item_template_url: MODALSELECTOR_TEMPLATES.generic.item,
				callback: null,
				paginated: false,
				current_page: 1,
				loading: false,
				search_query: null,
				showing_suggested: false,
				items_total_count: null,
				items: [],
				modal_placeholder: null
			};


			
			// Reset state
			// FIXME: function with both init and reset
			$scope.shutdown = function() {
				$log.debug("Resetting modal state...");
				
				$scope.state.active = false;
				$scope.state.active_resource = null;
				$scope.state.modal_title = null;
				$scope.state.resource_kind = 'generic';
				$scope.state.resource_service = null;
				$scope.state.resource_service_suggested = null;
				$scope.state.resource_options = {};
				$scope.state.choices_header_template_url = MODALSELECTOR_TEMPLATES.generic.header;
				$scope.state.choices_item_template_url = MODALSELECTOR_TEMPLATES.generic.item;
				$scope.state.callback = null;
				$scope.state.paginated = false;
				$scope.state.current_page = 1;
				$scope.state.loading = false;
				$scope.state.search_query = null;
				$scope.state.showing_suggested = false;
				$scope.state.items_total_count = null;
				$scope.state.items = [];
				$scope.state.modal_placeholder = null;
			};


			
			// Close modal
			$scope.close = function() {
				$scope.state.modal.modal('hide');
			};
			
			// Shutdown the modal when closed
			$scope.state.modal.on('hidden.bs.modal', function() {
				$scope.shutdown();
			});
			
			// Item was selected
			// * send with callback
			// * shut down modal selector
			$scope.select = function(item) {
				$log.debug("Item was selected");
				// $log.debug(item);
				if ($scope.state.resource_kind=="planning_technicians"){
					if (angular.isUndefined($rootScope.state)){
						$rootScope.state = {
							items_selected : []
						};
					}
					$rootScope.state.items_selected.push(item.id);
				}
				$scope.state.callback(item);
				$scope.close();
			};
			
			// Show initial options
			$scope.show_initial_items = function() {
				$log.debug('apiObjectModalSelectorController.show_initial_items()');
				
				$scope.state.paginated = false;
				$scope.state.current_page = 1;
				$scope.state.loading = true;
				var suggested = false;
				
				// Set active resource to suggested if available
				if ($scope.state.resource_service_suggested) {
					$scope.state.active_resource = $scope.state.resource_service_suggested;
					suggested = true;
				
				} else {
					$scope.state.active_resource = $scope.state.resource_service;
					suggested = false;
				}
				
				var options = angular.extend({}, $scope.state.resource_service_options, {
					page: 1,
					page_size: $scope.conf.page_size
				});
				
				$scope.state.active_resource.query(options, function(data) {
					$log.debug('show_initial_items() OK');
					// $log.debug(data);
					$scope.state.loading = false;
					$scope.state.items = data.results;
					$scope.state.items_total_count = data.count;
					$scope.state.paginated = !!(data.next || data.previous);
					$scope.state.showing_suggested = suggested;
					
				}, function(data) {
					$log.debug('show_initial_items() FAILED');
					$log.debug(data);
					
					$scope.state.loading = false;
					$scope.state.showing_suggested = suggested;
					
				});
			};
			
			// Search and update results
			$scope.search = function(query) {
				$log.debug('Searching for: ' + query);
				
				$scope.state.paginated = false;
				$scope.state.current_page = 1;
				$scope.state.loading = true;
				$scope.state.showing_suggested = false;
				$scope.state.active_resource = $scope.state.resource_service;
				
				var options = angular.extend({}, $scope.state.resource_service_options, {
					search: query,
					page: 1,
					page_size: $scope.conf.page_size
				});
				
				$scope.state.active_resource.query(options, function(data) {
					$log.debug('Search OK');
					// $log.debug(data);
					
					$scope.state.loading = false;
					$scope.state.items = data.results;
					$scope.state.items_total_count = data.count;
					$scope.state.paginated = !!(data.next || data.previous);
					
				}, function(data) {
					$log.debug('Search FAILED');
					$log.debug(data);
					
					$scope.state.loading = false;
					
				});
			};
			
			// Search: on search query changes
			$scope.$watch('state.search_query', function(value) {
				if ($scope.state.active) {
					if (value) {
						$scope.search(value);
					} else {
						$scope.show_initial_items();
					}
				}
			});
			
			// Page changed
			// this is used by a watch on the current page model
			// updates the items only if pagination is active
			// (pagination should be disabled before setting the page programatically)
			$scope.page_changed = function() {
				if ($scope.state.active && $scope.state.paginated) {
					$scope.state.loading = true;
					
					var options = angular.extend({}, $scope.state.resource_service_options, {
						page: $scope.state.current_page,
						page_size: $scope.conf.page_size
					});
					// Keep searching if search is active
					if ($scope.state.search_query) {
						angular.extend(options, {
							//q: $scope.state.search_query
							search: $scope.state.search_query
						});
					}
					
					// Get new page (on active resource)
					$scope.state.active_resource.query(options, function(data) {
						$log.debug('Loading page items OK');
						// $log.debug(data);
						
						$scope.state.loading = false;
						$scope.state.items = data.results;
						$scope.state.items_total_count = data.count;
					
					}, function(data) {
						$log.debug('Loading page items FAILED');
						// $log.debug(data);
						
						$scope.state.loading = false;
					
					});
				}
			};
			
			// Activate modal
			//
			// This is the main entry point to the widget
			// This widget is used by several models, requires initialization options
			//  
			// Options:
			//   modal_title: Modal title
			//   resource_kind: Resource kind name
			//   resource_display_name: Resource display name
			//   resource_display_name_plural: Resource display name plural
			//   resource_service: $resource
			//   resource_service_suggested: $resource for suggested options (optional)
			//   resource_service_options: $resource extra default options (optional)
			//   choices_item_template_url: Custom item template URL (optional)
			//   callback: function(value) callback function with selected object
			//
			$scope.$on('activate-modalselector-apiobject', function(event, options){
				$log.debug('GOT "activate-modalselector-apiobject"');
				// $log.debug(options);
				
				// Validate required options
				if (typeof options !== 'object' ||
					typeof options.modal_title === 'undefined' ||
					typeof options.resource_display_name === 'undefined' ||
					typeof options.resource_display_name_plural === 'undefined' ||
					typeof options.resource_kind === 'undefined' ||
					typeof options.resource_service === 'undefined' ||
					typeof options.callback === 'undefined') {
					$log.error("Event 'activate-modalselector-apiobject' is missing required options.");
					$log.error(options);
				
				} else {
					// Init state
					$scope.state.active = true;
					$scope.state.modal_title = options.modal_title;
					$scope.state.resource_display_name = options.resource_display_name;
					$scope.state.resource_display_name_plural = options.resource_display_name_plural;
					$scope.state.resource_kind = options.resource_kind;
					$scope.state.resource_service = options.resource_service;
					$scope.state.resource_service_suggested = options.resource_service_suggested || null;
					$scope.state.resource_service_options = options.resource_service_options || {};
					$scope.state.modal_placeholder = options.modal_placeholder || 'search';
					
					// Choices templates
					// Set according to resource kind if known
					// $log.debug(MODALSELECTOR_TEMPLATES[$scope.state.resource_kind]);
					// $log.debug($scope.state.resource_kind);
					if (MODALSELECTOR_TEMPLATES[$scope.state.resource_kind]) {
						$scope.state.choices_item_template_url = MODALSELECTOR_TEMPLATES[$scope.state.resource_kind].item || null;
						$scope.state.choices_header_template_url = MODALSELECTOR_TEMPLATES[$scope.state.resource_kind].header || null;
					}

					// DEPRECATED
					// Override if set by event options
					// if (typeof options.item_template_url !== 'undefined') {
					// 	$scope.state.choices_item_template_url = options.choices_item_template_url;
					// }
					
					$scope.state.callback = options.callback;
					
					// Activate
					$scope.show_initial_items();
					$scope.state.modal.modal('show');
				}
			});
		}
	]);


angular
	.module('servicepro.modalselector')
	.directive('apiObjectModalSelector', [
		'$log',
		
		function apiObjectModalSelectorFactory($log) {
			return {
				scope: true,
				templateUrl: '/static/servicepro/html/modalselector/modalselector-apiobject.html',
				controller: 'apiObjectModalSelectorController'
			};
		}]);



