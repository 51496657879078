
angular
	.module('servicepro.clienteditor')
	.controller('ClientEditorController', [
		'$log',
		'$scope',
		'$rootScope',
		'$filter',
		'$resource',
		'$timeout',
		'gettextCatalog',
		'apiURLs',
		'$http',

		function ClientEditorControllerFactory($log, $scope, $rootScope, $filter, $resource, $timeout, gettextCatalog, apiURLs, $http) {
            $log.debug('ClientEditorControllerFactory OK');
			$scope.state = {
				loading: false
			};

			$scope.get_distributor_resource = function() {
				return $resource(apiURLs.distributors, {});
			};

			$scope.get_salesrep_resource = function() {
				return $resource(apiURLs.salesrep, {});
			};

        }
    ]);
