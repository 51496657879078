/**
 * Created by agonzalez on 07/01/19.
 */

angular
	.module('servicepro.workorderresulteditor')
	.controller('WorkOrderResultEditorController', [
		'$log',
		'$scope',
		'$rootScope',
		'$filter',
		'$resource',
		'$timeout',
		'gettextCatalog',
		'apiURLs',
		'$http',
		'$compile',

		function WorkOrderResultEditorControllerFactory($log, $scope, $rootScope, $filter, $resource, $timeout, gettextCatalog, apiURLs, $http, $compile) {
            $log.debug('WorkOrderResultEditorControllerFactory OK');

			$scope.state = {
				loading: false,
				equipments: [],
			};

			// Object to store the dynamics variables in Equipment formset.
			$scope.Equipments = {};

			// Equipments.
			$scope.get_equipment_resource = function() {
				return $resource(apiURLs.equipmentmodels, {});
			};
			$scope.add_equipment = function () {
				var form_idx = $('#id_workorderresultequipment_set-TOTAL_FORMS').val(),
					html_text = $('#empty_form').html().replace(/__prefix__/g, form_idx);
				var html_compiled = $compile(html_text)($scope);
				angular.element($('#teamsList').append(html_compiled));
				dinamicDatePickerEquiment(form_idx);
				$('#id_workorderresultequipment_set-TOTAL_FORMS').val(parseInt(form_idx) + 1);
            };

            $scope.add_washing_machine = function () {
				var form_idx = $('#id_washing_machines-TOTAL_FORMS').val(),
					html_text = $('#empty_form_washing_machine').html().replace(/__prefix__/g, form_idx);
				var html_compiled = $compile(html_text)($scope);
				angular.element($('#washingList').append(html_compiled));
				$('#id_washing_machines-TOTAL_FORMS').val(parseInt(form_idx) + 1);
            };
        }
    ]);
