
angular
	.module('servicepro.common')
    .factory('apiURLs', [
        '$log',
        'APP_CONTEXT',
        function apiURLsFactory($log, APP_CONTEXT) {
            $log.debug("apiURLsFactory OK");

            // RADAR: Angular-Django API URLs
            // var urls = {
            //     assistance_request: '/mockup/api/assistance-request-list/:id',
            //     clients: '/mockup/api/clients-list/:id',
            //     distributors: '/mockup/api/distributors-list/:id',
            //     service_address: '/mockup/api/service-address-list/:id',
            //     technicians: '/mockup/api/technicians-list/:id'
            // };
            var urls = {
                assistance_request: '/api/v1/servicerequests/:id',
                clients: '/api/v1/clients/:id',
                distributors: '/api/v1/distributors/:id',
                equipmentmodels: '/api/v1/equipmentmodels/:id',
                service_address: '/api/v1/serviceaddresses/:id',
                technicians: '/api/v1/technicians/:id',
                analysis: {
                    summary: '/api/v1/analysis/summary/',
                    incidences: '/api/v1/analysis/incidences/',
                    hours: '/api/v1/analysis/hours/',
                    amounts: '/api/v1/analysis/amounts/',
                },
                salesrep: '/api/v1/salesrep/:id'

            };
            return urls;
        }
    ]);
