


// Daterange widget
// http://www.daterangepicker.com/
// https://github.com/dangrossman/bootstrap-daterangepicker
angular
	.module('servicepro.daterangewidget')
	.directive('daterangeWidget', [
		'$log',
		
		function daterangeWidgetFactory($log) {
			return {
				scope: {
					model_date_start: '=modelDateStart', // 'YYYY-MM-DD'
					model_date_end: '=modelDateEnd' // 'YYYY-MM-DD'
				},
				templateUrl: '/static/servicepro/html/daterangewidget/daterangewidget-widget.html',
				controller: [
					'$log',
					'$rootScope',
					'$scope',
					'$element',
					'$attrs',
					'$timeout',
					'gettextCatalog',
					'apiURLs',
					
					function DaterangeWidgetController($log, $rootScope, $scope, $element, $attrs, $timeout, gettextCatalog, apiURLs) {
						$log.debug('DaterangeWidgetController OK');
						
						$scope.display = '';
						$scope.date_model_format = 'YYYY-MM-DD'; // iso date
						
						$scope.locale = {
							"format": "DD/MM/YYYY", // TODO: get from django
							"separator": " - ",
							"applyLabel": gettextCatalog.getString("Apply"),
							"cancelLabel": gettextCatalog.getString("Cancel"),
							"fromLabel": gettextCatalog.getString("From"),
							"toLabel": gettextCatalog.getString("To"),
							"customRangeLabel": gettextCatalog.getString("Custom"),
							/// Week label
							"weekLabel": "W",
							"daysOfWeek": [
								/// Day of week abbr.
								gettextCatalog.getString("Su"),
								/// Day of week abbr.
								gettextCatalog.getString("Mo"),
								/// Day of week abbr.
								gettextCatalog.getString("Tu"),
								/// Day of week abbr.
								gettextCatalog.getString("We"),
								/// Day of week abbr.
								gettextCatalog.getString("Th"),
								/// Day of week abbr.
								gettextCatalog.getString("Fr"),
								/// Day of week abbr.
								gettextCatalog.getString("Sa")
							],
							"monthNames": [
								gettextCatalog.getString("January"),
								gettextCatalog.getString("February"),
								gettextCatalog.getString("March"),
								gettextCatalog.getString("April"),
								gettextCatalog.getString("May"),
								gettextCatalog.getString("June"),
								gettextCatalog.getString("July"),
								gettextCatalog.getString("August"),
								gettextCatalog.getString("September"),
								gettextCatalog.getString("October"),
								gettextCatalog.getString("November"),
								gettextCatalog.getString("December")
							],
							"firstDay": 1 // monday
						};
						
						$scope.format_dt_display = function(dt) {
							return dt.format($scope.locale.format);
						};
						
						$scope.format_dt_model = function(dt) {
							return dt.format($scope.date_model_format);
						};
						
						$scope.format_range_display = function(start, end) {
							return $scope.format_dt_display(start) + ' ' + $scope.locale.separator + ' ' + $scope.format_dt_display(end);
						};
						
						// Date range changed, update models
						// third parameter ('label') always undefined,
						// at least with a div instead of an input.
						// start and end dates are Moment.js instances
						// http://momentjs.com/
						$scope.update = function(start, end) {
							// $log.debug("Date range changed");
							// $log.debug(start.toDate());
							// $log.debug(end.toDate());
							// $log.debug(label);
							$timeout(function() {
								$scope.display = $scope.format_range_display(start, end);
								$scope.model_date_start = $scope.format_dt_model(start);
								$scope.model_date_end = $scope.format_dt_model(end);
								
							}, 0);
						};
					}
				],
				link: function(scope, element, attrs, controller) {
					if (typeof attrs.modelDateStart === 'undefined' ||
						typeof attrs.modelDateEnd === 'undefined') {
						$log.error("Missing required attributes.");
						$log.debug(attrs);
					}

					// Init bootstrap-daterangepicker
					// from model initial string iso calendar date
					var date_start_dt = moment(scope.model_date_start, scope.date_model_format);
					var date_end_dt = moment(scope.model_date_end, scope.date_model_format);
					element.find('div').daterangepicker({
						locale: scope.locale,
						startDate: date_start_dt,
						endDate: date_end_dt
					}, scope.update);
					
					// Update display
					scope.display = scope.format_range_display(date_start_dt, date_end_dt);
					
				}
			};
		}
	]);