
// Modal Selector with set choices

angular
	.module('servicepro.modalselector')
	.directive('choicesModalSelector', [
		'$log',
		
		function choicesModalSelectorFactory($log) {
			return {
				scope: true,
				templateUrl: '/static/servicepro/html/modalselector/modalselector-choices.html',
				controller: [
					'$log',
					'$scope',
					'$element',
					'$attrs',
					'$window',
					'gettext',
					'gettextCatalog',
					'utilCommon',
		
					function choicesModalSelectorController($log, $scope, $element, $attrs, $window, gettext, gettextCatalog, utilCommon) {
						$log.debug('choicesModalSelectorController OK');
			
						$scope.state = {
							active: false,
							modal: $($element).find('.modal'),
							modal_title: null,
							choices: [],
							choices_max: 0,
							model: [],
							placeholder: null,
						};
			
						// Reset state
						$scope.shutdown = function() {
							$log.debug("Resetting modal state...");
							
							$scope.state.active = false;
							$scope.state.modal_title = null;
							$scope.state.choices = [];
							$scope.state.choices_max = 0;
							$scope.state.model = [];
							$scope.state.placeholder = null;
						};
			
						// Close modal
						$scope.close = function() {
							$scope.state.modal.modal('hide');
						};
			
						// Shutdown the modal when closed
						$scope.state.modal.on('hidden.bs.modal', function() {
							$scope.shutdown();
						});
						
						// Add item
						$scope.add = function(item) {
							$scope.state.model.push(item);
						};
						
						// Remove item
						$scope.remove = function(item) {
							$log.debug("Removing item");
							utilCommon.remove_object_by_id($scope.state.model, item.id);
						};
						
						// Is this item selected?
						$scope.is_selected = function(item) {
							return !!utilCommon.find_object_by_id($scope.state.model, item.id);
						};
						
						// Are the options maxed out?
						$scope.is_maxed_out = function() {
							return $scope.state.model.length >= $scope.state.choices_max;
						};
						
						// Toggle item selection
						$scope.toggle = function(item) {
							$log.debug("toggle()");
							// $log.debug(item);
							
							// Item is already selected, deselect
							if ($scope.is_selected(item)) {
								$scope.remove(item);
							
							// Item is not selected
							} else {
								// Room available for more items?
								if (!$scope.is_maxed_out()) {
									$scope.add(item);
								// Maxed out. Show explanation
								} else {
									var msg = gettextCatalog.getString("You have selected the maximum number of items");
									$window.alert(msg + " (" + $scope.state.choices_max + ")");
								}
							}
						};
						
						// Activate modal
						//
						// This is the main entry point to the widget
						// This widget is used by several models, requires initialization options
						//  
						// Options:
						//   modal_title: Modal title
						//   choices: items
						//   choices_max: max number of choices
						//   callback: function(value) callback function with selected object
						//
						$scope.$on('activate-modalselector-choices', function(event, options){
							$log.debug('GOT "activate-modalselector-choices"');
							// $log.debug(options);
				
							// Validate required options
							if (typeof options !== 'object' ||
								typeof options.modal_title === 'undefined' ||
								typeof options.choices === 'undefined' ||
								typeof options.choices_max === 'undefined' ||
								typeof options.model === 'undefined') {
								$log.error("Event 'activate-modalselector-choices' is missing required options.");
								$log.error(options);
				
							} else {
								// Init state
								$scope.state.active = true;
								$scope.state.modal_title = options.modal_title;
								$scope.state.choices = options.choices;
								$scope.state.choices_max = options.choices_max;
								$scope.state.model = options.model;
								$scope.state.placeholder = options.placeholder;
				
								// Activate
								$scope.state.modal.modal('show');
							}
						});
					}
				]
			};
		}]);



