// Model widget
// Shows the state of a modal-selectable model
// Invokes modal selector to choose model
// Selection can be cleared
angular
	.module('servicepro.modelwidget')
	.directive('modelWidget', [
		'$log',

		function modelWidgetFactory($log) {
			return {
				scope: {
					model: '=',
					kind: '@kind',
					display_name: '@displayName',
					display_name_plural: '@displayNamePlural',
					field_name: '@fieldName',
					button_label: '@buttonLabel',
					button_label_with_items: '@buttonLabelWithItems',
					register_label: '@registerLabel',
					resource_getter: '&resourceGetter',
					resource_label: '@resourceLabel',
					resource_initial_id: '@resourceInitialId',
					resource_initial_ids: '@resourceInitialIds',
					resource_initial_label: '@initialResourceLabel',
					resource_link_url: '@resourceLinkUrl',
					model_changed_callback: '&modelChangedCallback',
					resource_getter_selector: '&resourceGetterSelector',
					disabled: '<disabledIf',
					placeholder: '@placeholder',
					modal_placeholder: '@modalPlaceholder'
				},
				// transclude: 'element', // (ad-hoc template)
				templateUrl: function(elem, attr) {
					$log.debug('template loading');
					var template = '/static/servicepro/html/modelwidget/modelwidget-widget.html';
					if (attr.templateName) {
						template = '/static/servicepro/html/modelwidget/' + attr.templateName + '.html';
					}
					return template;
				},

				controller: [
					'$log',
					'$rootScope',
					'$scope',
					'$timeout',
					'$element',
					'$attrs',
					'$resource',
					'$window',
					'$location',
					'apiURLs',
					
					function modelWidgetControllerFactory($log, $rootScope, $scope, $timeout, $element, $attrs, $resource,
														  $window, $location, apiURLs) {
						$log.debug('modelWidgetControllerFactory OK');

						// Resolve resource
						// Either from resource label or getter
						// one of them must be present (validated in link()), getter has priority
						// The resource getter may return null, which means it is unavailable
						$scope.resolve_resource = function() {
							if (typeof $attrs.resourceGetter !== 'undefined') {
								// $log.debug($attrs.resourceGetter);
								// $log.debug($scope.resource_getter());
								return $scope.resource_getter();
						
							} else if (typeof $attrs.resourceLabel !== 'undefined') {
								return $resource(apiURLs[$scope.resource_label]);
							}
						};
						$scope.resolve_resource_selector = function () {
							if (typeof $attrs.resourceGetterSelector !== 'undefined'){
								return $scope.resource_getter_selector();
							} else {
								return $scope.resolve_resource();
							}
                        };
						
						// Activate API object selector for this widget
						$scope.activate_selector = function() {
							$log.debug("modelWidget.activate_selector()");
							if (!$scope.disabled) {
								// Resolve resource
								var resource = $scope.resolve_resource_selector();

								if (resource) {
									var options = {
										modal_title: $scope.display_name_plural,
										resource_display_name: $scope.display_name,
										resource_display_name_plural: $scope.display_name_plural,
										resource_kind: $scope.kind,
										resource_service: resource,
										resource_service_suggested: $scope.resource_initial_label ? $resource(apiURLs[$scope.resource_initial_label]) : null,
										placeholder: $scope.placeholder || 'None selected',
										modal_placeholder: $scope.modal_placeholder || 'Search',
										callback: function(selected) {
											// $log.debug("modelWidgetControllerFactory callback()");
											// $log.debug(selected);
											if ($scope.model instanceof Array) {
											    $scope.model.push(selected);
											    if($scope.field_name=='service_address'){
											    	$rootScope.selectedModel.push(selected);
											    }
											    
											} else {
											    $scope.model = selected;
											    if($scope.field_name=='service_address'){
											    	$rootScope.selectedModel = selected;
											    }
											}

											if ($scope.kind == "servicerequest") {
												$window.location.href = $location.path($window.location.pathname).search({"from-request": selected.id}).url();
											}
											if ($scope.model_changed_callback) {
												$scope.model_changed_callback({model: $scope.model});
											}

											if ($scope.kind == "client") {
												var service_address_url = $scope.$parent.get_service_resource_specific_id(selected.id); 
												if(service_address_url){
													service_address_url.query({}, function(data) {
														if(data.count == 1){
															$rootScope.selectedModel = data.results[0];
															$scope.$parent.state.service_address=$rootScope.selectedModel;
														}
													});
												}
											}

											if ($scope.kind == "planning_technicians"){
												window.sumTotalCost();
											}
										}
									};
									$rootScope.$broadcast('activate-modalselector-apiobject', options);
								} else {
									$log.error("Missing main resource, ignoring request to open selector");
								}
							
							} else {
								$log.error("Selector cannot be activated because widget is disabled.");
							}
						};
						
						// Clear model
						$scope.clear = function($event) {
							$event.stopPropagation();
							$log.debug("modelWidget.clear()");
							if ($scope.model instanceof Array) {
							    $scope.model = [];
							    $rootScope.selectedModel = [];
							} else {
							    $scope.model = null;
							    $rootScope.selectedModel = null;
							}

							if ($scope.model_changed_callback) {
								$scope.model_changed_callback();
							}
						};

						// Remove Item model
						$scope.remove = function(item) {
							$log.debug("modelWidget.remove()");
							var index = $scope.model.indexOf(item);
							if (index > -1) {
                                $scope.model.splice(index, 1);
                            }
                            if ($scope.kind=="planning_technicians"){
                            	var referentSelectedItems = $scope.$parent.$parent.$root.state.items_selected;
                            	referentSelectedItems.splice(referentSelectedItems.indexOf(item.id), 1);
                            	window.sumTotalCost();
                            }
                            
						};


						// Init model from initial ID
						if ($scope.resource_initial_id &&
							$scope.resource_initial_id != "None" &&
							/^\d+$/.test($scope.resource_initial_id)){
                            var ResourceId = $scope.resolve_resource();
							$scope.model = ResourceId.get({id:$scope.resource_initial_id});
							if($scope.kind=='center'){
								$rootScope.selectedModel = ResourceId.get({id:$scope.resource_initial_id});
							}
						}

						// Init model from initial IDs
						if ($scope.resource_initial_ids &&
							$scope.resource_initial_ids != "None"){
                            var ResourceIds = $scope.resolve_resource();
                            var resource_initial_ids = $scope.resource_initial_ids;
                            if (typeof resource_initial_ids == "string") {
                                resource_initial_ids = resource_initial_ids.replace(/'/g, "");
                            }
							var ids = JSON.parse(resource_initial_ids);

							if (angular.isUndefined($rootScope.state)){
								$rootScope.state = {
									items_selected : []
								};
							}

                            var model = [];
                            for (var index in ids){
                                var id = ids[index];
                                var technician = ResourceIds.get({id:id});
							    model.push(technician);
							    $rootScope.state.items_selected.push(id);
                            }
							$scope.model = model;
						}
						
						// Disabled class
						$scope.$watch('disabled', function(nv, ov) {
							if (!!nv) {
								$element.addClass('disabled');
							} else {
								$element.removeClass('disabled');
							}
						});
					},
				],
				link: function(scope, element, attrs, controller) {
					if (typeof attrs.model === 'undefined' ||
						typeof attrs.kind === 'undefined' ||
						typeof attrs.displayName === 'undefined' ||
						typeof attrs.displayNamePlural === 'undefined' ||
						(typeof attrs.resourceLabel === 'undefined' && typeof attrs.resourceGetter === 'undefined')) {
						
						$log.error("Missing required attributes.");
						$log.debug(attrs);
						
					}
				}
			};
		}
	]);